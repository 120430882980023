import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Box, Grid, Typography, Container, Fade } from '@material-ui/core';
import ImageSlider from '../../components/events/ImageSlider';
import { LuGalleryHorizontal, LuLayoutGrid, LuList } from 'react-icons/lu';
import { useState, useEffect } from 'react';
import '../../assets/scss/events/eventsDisplay.scss';
import getEventLists from '../../hooks/@events/getEventLists';
import getBusinessProfile from '../../hooks/@common/getBusinessProfile';
import getWidgetBranding from '../../hooks/@common/getWidgetBranding';
import _ from 'lodash';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { GoAlert } from 'react-icons/go';

import iconWabi from '../../assets/images/icon-wabi.png';

function Events({}) {
  const { key } = useParams();
  const [resetSlider, setResetSlider] = useState(false);
  const [businessProfile, setBusinessProfile] = useState({});
  const [eventLists, setEventLists] = useState({});
  const [widgetBranding, setWidgetBranding] = useState({});
  const [displayClass, setDisplayClass] = useState('portrait');

  const location = useLocation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const layout = queryParams.get('layout');

    if (layout) {
      setDisplayClass(layout);
    }
  }, [location]);

  const changeDisplay = () => {
    setDisplayClass((prevClass) => {
      setResetSlider(true);
      let newClass;
      if (prevClass === 'portrait') {
        newClass = 'grid';
      } else if (prevClass === 'grid') {
        newClass = 'list';
      } else {
        newClass = 'portrait';
      }
      const url = new URL(window.location);
      url.searchParams.set('layout', newClass);
      window.history.pushState({}, '', url);
      return newClass;
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const [eventListsRes, businessRes, widgetBrandingRes] = await Promise.all([
          getEventLists(key),
          getBusinessProfile(key),
          getWidgetBranding(key)
        ]);
        const today = new Date();
        const filteredEvents = eventListsRes.data.filter((event) => {
          const expirationDate = new Date(event.data.expirationDate);
          return today <= expirationDate || event.type !== 'TICKET';
        });
        setEventLists(filteredEvents);
        setBusinessProfile(businessRes.data);
        setWidgetBranding(widgetBrandingRes.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [key]);

  const DisplayIcon = ({ IconComponent, label, onClick }) => (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <IconComponent style={{ cursor: 'pointer', fontSize: '24px' }} onClick={onClick} />
      <Typography
        variant="body1"
        style={{
          fontSize: '12px',
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, 0%)'
        }}
      >
        {label}
      </Typography>
    </div>
  );
  console.log('qweqwe')
  return (
    <>
      {loading ? (
        <Fade in={loading} unmountOnExit>
          <Container
            maxWidth="lg"
            style={{
              backgroundColor: '#000',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh'
            }}
            id="eventTop"
          >
            <AiOutlineLoading3Quarters className="loading" />
          </Container>
        </Fade>
      ) : (
        <Fade in={!loading} timeout={1200} unmountOnExit>
          <Container
            maxWidth={false}
            style={{
              backgroundColor: '#000',
              minHeight: '100vh',
              color: '#fff',
              padding: '20px',
              position: 'relative'
            }}
          >
            <div className="globalInner">
              <Box className="fixed-items">
                <Grid
                  container
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Grid item>
                    <img src={iconWabi} alt="Wabi Icon" style={{ width: '65px' }} />
                  </Grid>
                  <Grid item>
                    {displayClass === 'list' && (
                      <DisplayIcon IconComponent={LuList} label="List" onClick={changeDisplay} />
                    )}
                    {displayClass === 'grid' && (
                      <DisplayIcon
                        IconComponent={LuLayoutGrid}
                        label="Grid"
                        onClick={changeDisplay}
                      />
                    )}
                    {displayClass === 'portrait' && (
                      <DisplayIcon
                        IconComponent={LuGalleryHorizontal}
                        label="Portrait"
                        onClick={changeDisplay}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
              {!_.isEmpty(eventLists) ? (
                <div className={displayClass} style={{ paddingTop: '60px' }}>
                  {eventLists.map((event) => (
                    <ImageSlider
                      key={event.id}
                      eventData={event}
                      resetSliderProp={resetSlider}
                      images={event.data.files.length > 0 ? event.data.files : widgetBranding.files}
                      token={key}
                      layout={displayClass}
                      widgetBranding={widgetBranding}
                      photo={businessProfile.user.business_profile.profile_pic}
                    />
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    paddingTop: '200px',
                    backgroundColor: widgetBranding.background_colour || '#000',
                    height: '100dvh'
                  }}
                >
                  <GoAlert
                    style={{
                      fontSize: '64px',
                      color: '#fff',
                      textAlign: 'center',
                      width: '100%'
                    }}
                  />
                  <Typography style={{ textAlign: 'center', marginTop: '24px' }}>
                    Sorry, there is no available events.
                  </Typography>
                </div>
              )}
            </div>
          </Container>
        </Fade>
      )}
    </>
  );
}

export default Events;
