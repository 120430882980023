// ExperienceCardList.jsx
import React from 'react';
import '../../assets/scss/ticket.scss';
import Grid from '@material-ui/core/Grid';
import { Typography, Box } from '@material-ui/core';
import { s3Bucket } from '../../config';

const ExperienceCardList = ({ experiences, handleSelectExperience }) => {
  if (!Array.isArray(experiences)) {
    return null;
  }

  const findTimeRangeLabel = (untilTime, timeRangeArray) => {
    const matchingTimeRange = timeRangeArray.find((range) => range.value === untilTime);
    return matchingTimeRange ? matchingTimeRange.label : null;
  };
  const handleClick = (
    experienceId,
    experienceName,
    tickets,
    price,
    dayOfWeek,
    startdate,
    endDate,
    experienceDescription,
    experiencePhoto,
    sold,
    shiftName,
    shiftUntilTime,
    shiftTimeRange
  ) => {
    handleSelectExperience(
      experienceId,
      experienceName,
      tickets,
      price,
      dayOfWeek,
      startdate,
      endDate,
      experienceDescription,
      experiencePhoto,
      sold,
      shiftName,
      shiftUntilTime,
      shiftTimeRange
    );
  };

  return (
    <Grid container>
      {experiences
        .filter((experience) => experience.status === 1)
        .filter((experience) => {
          if (experience.no_of_ticket === 0) {
            return true;
          } else {
            return experience.no_of_ticket - experience.ticket_sold > 0;
          }
        })
        .map((experience, index) => (
          <Grid
            onClick={() =>
              handleClick(
                experience.id,
                experience.exp_name,
                experience.no_of_ticket,
                experience.price,
                experience.experience_connection.day_of_week,
                experience.experience_connection.start_date,
                experience.experience_connection.end_date,
                experience.exp_description,
                experience.photo,
                experience.ticket_sold,
                experience.experience_connection.shift.name,
                experience.experience_connection.until_time,
                experience.experience_connection.shift.time_range
              )
            }
            item
            key={index}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
          >
            <div>
              <div className="ticket">
                <Box>
                  <img
                    className="ticketImage"
                    src={s3Bucket + experience.photo}
                    alt={experience.exp_name}
                    onError={(e) => {
                      e.target.src =
                        'https://www.survivorsuk.org/wp-content/uploads/2017/01/no-image.jpg';
                    }}
                    style={{
                      objectFit: 'cover', // or 'contain' based on your preference
                      width: '100%',
                    }}
                  />
                </Box>
                <div className="ticketTitle">
                  {experience.exp_name}
                  {experience.experience_connection.until_time !== null && experience.experience_connection.until_time !== 0  && (
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: '12px', fontStyle: 'italic' }}
                    >
                      Available for reservation until{' '}
                      {findTimeRangeLabel(
                        experience.experience_connection.until_time,
                        experience.experience_connection.shift.time_range
                      )}
                    </Typography>
                  )}
                </div>
                <div className="ticketDetail">
                  <Typography variant="body2">{experience.exp_description}</Typography>
                </div>
                <div className="ticketRip">
                  <div className="circleLeft"></div>
                  <div className="ripLine"></div>
                  <div className="circleRight"></div>
                </div>
                <div className="ticketSubDetail">
                  <div>${experience.price}</div>
                </div>
              </div>
              <div className="ticketShadow"></div>
            </div>
          </Grid>
        ))}
    </Grid>
  );
};

export default ExperienceCardList;
