import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Material-UI
import {
  Grid,
  Container,
  Typography,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

// Styles
import '../../assets/css/booking/bookingDetails.css';

// Icons
import { FaRegClock } from 'react-icons/fa6';
import { MdPeopleAlt } from 'react-icons/md';

// Hooks
import useBusinessProfile from '../../hooks/@booking/useBusinessProfile';
import getMyBooking from '../../hooks/@booking/useMyBooking';
import { cancelBooking } from '../../hooks/@booking/useCancelBooking';

// Utils
import { s3Bucket } from '../../config';
import moment from 'moment';

const bookingStatuses = [
  'Confirmed', // 0
  'ALL', // 1
  'Billed', // 2
  'Unbill', // 3
  'Seated', // 4
  'Unseat', // 5
  'Finished', // 6
  'No Show', // 7
  'Cancelled', // 8
  'Seat Not WaitList', // 9
  'WaitList', // 10
  'OverTime', // 11
  'Upcoming', // 12
  'Late', // 13
  'Need Attention', // 14
  'Partially Seated', // 15
  'Unfinished', // 16
  'Pending' // 17
];

const BookingDetails = () => {
  const [status, setStatus] = useState('confirmed');
  const { key, id } = useParams();
  const { businessProfileData } = useBusinessProfile(key);
  const [myBooking, setMybooking] = useState(null);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const { businessName, businessAddress, photo } = businessProfileData;

  const getStatusChip = (status) => {
    return (
      <Chip
        label={bookingStatuses[status]}
        style={{
          backgroundColor: status === 8 ? '#f44336' : '#3F59E4',
          color: '#fff',
          fontWeight: 'bold',
          fontFamily: 'Inter',
          borderRadius: '20px',
          padding: '20px',
          textTransform: 'capitalize',
          letterSpacing: '1px'
        }}
      />
    );
  };

  const formatDateTime = (dateString) => {
    const date = moment(dateString);
    const formattedDate = date.format('ddd, D MMM YYYY'); // Short form of day
    const formattedTime = date.format('h:mm a'); // Time in 12-hour format

    return (
      <strong>
        {formattedDate}
        <br />
        at {formattedTime}
      </strong>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await getMyBooking(id, key);

      if (result.status === 200) {
        setMybooking(result.data);
        setStatus(Number(result.data.status));
      }
    };

    fetchData();
  }, [id, key]);

  const handleCancelClick = () => {
    setOpenCancelDialog(true);
  };

  const handleCancel = async () => {
    try {
      const result = await cancelBooking(id, key);

      if (result.status === 200) {
        setStatus(8);
        setOpenCancelDialog(false);
      } else {
        console.error('Failed to cancel booking:', result.message);
      }
    } catch (error) {
      console.error('Error while cancelling booking:', error);
    }
  };

  const handleClose = () => {
    setOpenCancelDialog(false);
  };

  return (
    <div className="wabi-root">
      <Container maxWidth="md">
        <Grid container className="wabi-container">
          <Typography variant="h6" className="wabi-header">
            Manage Reservation
          </Typography>

          {/* Two-Column Layout */}
          <Grid container spacing={2} style={{ marginTop: 15 }}>
            {/* First Column: Photo */}
            <Grid item xs={4} sm={4}>
              <Box
                sx={{
                  width: '97px',
                  height: '92px',
                  padding: '13px',
                  backgroundColor: '#1E1F24',
                  borderRadius: '10px'
                }}
              >
                <img
                  src={s3Bucket + photo}
                  alt={businessName}
                  style={{ width: '100%', height: '100%', borderRadius: '10px' }}
                />
              </Box>
            </Grid>

            {/* Second Column: Details */}
            <Grid item xs={8} sm={8}>
              <Typography variant="body1" className="wabi-title">
                {businessName} <br />
                <span className="wabi-subtitle">{businessAddress}</span>
              </Typography>

              {/* Status Badge */}
              <Grid container style={{ marginBottom: '10px', marginTop: '20px' }}>
                {getStatusChip(status)}
              </Grid>

              <Grid container>
                {/* Date and Time */}
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                  <FaRegClock className="clock" style={{ marginRight: '15px' }} />
                  {formatDateTime(myBooking?.booking_date)}
                </Grid>
              </Grid>

              <Grid container>
                {/* Guests */}
                <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: '25px' }}>
                  <MdPeopleAlt className="clock" style={{ marginRight: '15px' }} />
                  <strong>
                    {myBooking?.guest?.first_name} {myBooking?.guest?.last_name}
                    <br />
                    {myBooking?.party_size} Guests
                  </strong>
                </Grid>
              </Grid>

              {status === 0 && ( // Only show if status is UNCONFIRMED
                <Grid container style={{ marginTop: 40 }} justifyContent="center">
                  <Button
                    variant="outlined"
                    style={{
                      backgroundColor: '#000',
                      color: '#fff',
                      borderColor: '#fff',
                      borderRadius: '5px',
                      padding: '10px 20px',
                      width: '134px',
                      textTransform: 'capitalize',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: 600,
                      lineHeight: '19.36px',
                      textAlign: 'center'
                    }}
                  >
                    Reschedule
                  </Button>
                </Grid>
              )}

              {/* Button Row: Cancel */}
              {status === 0 && ( // Only show if status is UNCONFIRMED
                <Grid container style={{ marginTop: 30 }} justifyContent="center">
                  <Button
                    variant="outlined"
                    style={{
                      backgroundColor: '#000',
                      color: '#fff',
                      borderColor: '#fff',
                      borderRadius: '5px',
                      padding: '10px 20px',
                      width: '134px',
                      textTransform: 'capitalize',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: 600,
                      lineHeight: '19.36px',
                      textAlign: 'center'
                    }}
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {/* Confirmation Dialog with Black Theme */}
      <Dialog
        open={openCancelDialog}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#121212', // Dark background
            color: '#ffffff', // White text
            borderRadius: '8px' // Optional: round corners
          }
        }}
      >
        <DialogTitle style={{ color: '#ffffff' }}>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <Typography style={{ color: '#ffffff' }}>Do you want to cancel your booking?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleCancel} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BookingDetails;
