// styles.js

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 75,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      padding: 0,
    },
  },
  button: {
    margin: theme.spacing(1),
    color: '#ffffff',
    width: '120px',
    height: '75px',
    borderRadius: '10px',
    background: 'linear-gradient(180deg, #FD6C57, #FD3366 100%)',
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(3),
  },
  imagePaper: {
    borderRadius: '20px',
  },
  selectPaper: {
    boxShadow: '0px 4px 4px 0px #00000040',
    borderRadius: '40px',
    border: '1px solid #F5F5F5'
  },
  fullWidthGrid: {
    width: '100%', // Make the Grid take 100% width
  },
  select: {
    width: '100% !important',
    '&:focus': {
      outline: 'none',
    },
  },
  override: {
    display: 'block',
    margin: '0, auto',
    borderColor: 'red',
  },
}));

export default useStyles;
