import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { UseSearchableAction } from '../../hooks/@booking/useSearchableAction';

const VisitCountFromUuid = ({ eventData }) => {
  const { key } = useParams();
  const [isVisit, setIsVisit] = useState(false);
  useEffect(() => {
    // Check if UUID already exists in localStorage
    let visitCookie = Cookies.get('uuid');
    if (!visitCookie) {
      // Generate new UUID if not exists and set in localStorage
      const newUuid = uuidv4();
      Cookies.set('uuid', newUuid, { expires: 7 }); // Cookie valid => 7days
    } else {
    }
    setIsVisit(eventData.is_visited);
    const getUuid = Cookies.get('uuid');
    if (isVisit === false) {
      const fetchData = async () => {
        const response = await UseSearchableAction(key, {
          id: eventData.id,
          action: 'visits',
          user_uuid: getUuid
        });
      };
      fetchData();
    }
  }, [eventData]);
  return <div></div>;
};

export default VisitCountFromUuid;
