export const VIEWSTATE = {
  LOADING: 0,
  INITIAL: 1,
  EXPERIENCE: 2,
  TABLE_BOOKING: 3
};

export const RECURRINGVALUE = {
  NONE: 0,
  EVERYDAYOFMONTH: 1,
  EVERYDATEOFMONTH: 2,
  EVERYNUMWEEKOFMONTH: 3,
  EVERYMONTHDATEOFYEAR: 4,
  EVERYWEEKOFTHEYEAR: 5
};

export const RECURRINGTYPE = {
  NONE: -1,
  ANNUALLY: 0,
  MONTHLY: 1,
  WEEKLY: 2
};

export const TABLETYPE = {
  singleTable: 1,
  twoSingleTable: 2,
  threeSingleTable: 3,
  singlePairTable: 4,
  twoSinglePairTable: 5,
  threeSinglePairTable: 6,
  fourSinglePairTable: 7,
  fourPersonSingleTable: 8,
  fourPersonRoundTable: 9,
  sixPersonRoundTable: 10,
  eightPersonRoundTable: 11,
  tenPersonRoundTable: 12,
  halfSeatRoundTable: 13,
  twoPersonRoundTable: 14
};

export const DAYOFWEEK = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
};
export const DIALOGMESSAGE = {
  SUBMISSION_FAILED: 'Submission Failed',
  SUBMISSION_FAILED_DESCRIPTION:
    'An error occurred while attempting to submit. Please try again later.',
  COMMON_FAILED_DESCRIPTION: 'An error occurred. Please try again later'
};
