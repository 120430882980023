import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import { Grid, Typography } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { s3Bucket } from '../../config';

// CSS
import 'swiper/css';
import 'swiper/css/pagination';
import '../../assets/scss/events/eventsDisplay.scss';
import '../../assets/scss/events/detail.scss';

// Utils
import _ from 'lodash';

const ImageDetailSlider = ({ eventTitle, images, widgetBranding, photo = null }) => {
  const playerRef = useRef(null);
  return (
    <Grid className="post-card" container position="relative">
      <Grid item className="post-card_contents" xs={12} style={{ position: 'relative' }}>
        <Swiper
          className="swiperWrapper"
          slidesPerView={'auto'}
          initialSlide="0"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false
          }}
          pagination={{
            clickable: true
          }}
          navigation={true}
          modules={[Autoplay, Pagination]}
        >
          {!_.isEmpty(images) ? (
            images.map((image, index) => (
              <SwiperSlide key={index} style={{ textAlign: 'center' }}>
                {image.isVideo ? (
                  <ReactPlayer
                    ref={playerRef}
                    url={image.src}
                    playing={true}
                    controls={false}
                    muted={true}
                    loop={true}
                    className="videoPlayer"
                    style={{ width: '100%', height: '100%' }}
                  />
                ) : (
                  <img src={`${s3Bucket}${image.path}`} alt={image.alt} />
                )}
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide style={{ textAlign: 'center' }}>
              <img
                src= {`${s3Bucket}${photo}` || "https://www.survivorsuk.org/wp-content/uploads/2017/01/no-image.jpg"}
                style={{
                  objectFit: 'cover',
                  width: '100%'
                }}
                alt="No images available"
              />
            </SwiperSlide>
          )}
        </Swiper>
        <Typography
          variant="h6"
          className="detail-title"
          style={{ fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit' }}
        >
          {eventTitle}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ImageDetailSlider;
