import axios from 'axios';
import { URL } from '../../config';

export const getRoster = async (schedule_id, employee_id, token) => {
  try {
    const params = {
        'employee_schedule_date_id': schedule_id,
        'employee_id': employee_id
      };

      const response = await axios.get(`${URL}/widget/roster`, {
        params: params,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

    const res = {
      data: response.data.roster,
      status: response.status,
      error: null,
      isLoading: false,
    };

    return res;
  } catch (error) {
    console.error('Error creating booking:', error);

    const res = {
      data: null,
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data.message : 'Internal Server Error',
      isLoading: false,
    };

    return res;
  }
};