import axios from 'axios';
import { URL } from '../../config';
import _ from 'lodash';
/**
 * Asynchronous function to retrieve event detail.
 * @param {string} key - The key used for authentication.
 * @returns {Promise<Object>} - Promise containing the result of event detail retrieval.
 * @throws {Error} - If an error occurs.
 */
const getEntityEventDetail = async (id, key, type, user_uuid) => {
  try {
    if (_.isEmpty(id)){
      return {
        data: null,
        status: true,
        error: null,
        isLoading: false
      }
    }

    const response = await axios.get(
      `${URL}/widget/entity/search?type=${type}&id=${id}&user_uuid=${user_uuid}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${key}`
        }
      }
    );

    const res = {
      data: response.data.data[0],
      status: response.status,
      error: null,
      isLoading: false
    };
    if (res.data === undefined) {
      return await getEntityEventDetail(id, key, 'post');
    } else {
      return res;
    }
  } catch (error) {
    console.error('Error get entity event detail:', error);

    const res = {
      data: null,
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data.message : 'Internal Server Error',
      isLoading: false
    };

    return res;
  }
};
export default getEntityEventDetail;
