import React, { useState, useRef, useEffect } from 'react';
import { Modal, Chip, TextField, Typography, Box, Button } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { format } from 'date-fns';
import 'swiper/css';
import '../../assets/css/roster/app.css';

const AvailableDate = ({ shiftData, onSelect }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    setIsDisabled(shiftData.availabilityLists.length === 0);
  }, [shiftData, shiftData.availabilityLists]);

  // Processing when availability button is pressed
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [showSwipeRequest, setShowSwipeRequest] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const swiperRef = useRef(null);
  const [shiftIds, setShiftId] = useState([]);
  const [type, setType] = useState(1);

  const changeShift = (selectedItem, onlyOneChip = false, type = 1) => {
    setShowSwipeRequest(false);
    setType(type);
    if (swiperRef.current && !isSelected) {
      setIsSelected(true);
    }
    const newSelectedItem = {
      id: selectedItem.id,
      name: selectedItem.name,
      color: selectedItem.color,
      className: selectedItem.className,
      onlyOneChip: onlyOneChip
    };
    if (onlyOneChip) {
      setSelectedShifts([newSelectedItem]);
    } else {
      if (selectedShifts[0] && selectedShifts[0].onlyOneChip) {
        selectedShifts.shift();
      }
      const uniqueArray = [
        ...new Set([...selectedShifts, newSelectedItem].map(JSON.stringify))
      ].map(JSON.parse);
      setSelectedShifts(uniqueArray);
    }
    swiperRef.current.swiper.slideTo(1);
  };

  useEffect(() => {
    if (selectedShifts[0]) {
      if (selectedShifts[0].id === null) {
        setShiftId([]);
      } else if (selectedShifts[0].id.length > 0) {
        setShiftId(selectedShifts[0].id);
      } else {
        setShiftId(selectedShifts.map((item) => item.id));
      }
    }
  }, [selectedShifts]);

  // Add note processing
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [noteText, setNoteText] = useState('');
  const handleOpenPopup = () => {
    setPopupOpen(true);
  };
  const handleClosePopup = () => {
    setPopupOpen(false);
    swiperRef.current.swiper.slideTo(1);
  };
  const handleNoteChange = (event) => {
    setNoteText(event.target.value);
  };
  const handleSubmit = () => {
    setNoteText(noteText);
    handleClosePopup();
  };

  // Click processing
  const handleClick = () => {
    if (selectedShifts.length > 0) {
      const data = {
        date: format(shiftData.date, 'yyyy-MM-dd'),
        type: type,
        shiftIds: shiftIds,
        note: noteText
      };
      onSelect(shiftData.dayOfWeek, data);
    }
  };
  useEffect(() => {
    handleClick();
  }, [shiftIds, noteText]);

  return (
    <Box
      className={'roster_card_s' + (isDisabled ? ' roster_card_s__disabled' : '')}
      style={{ position: 'relative' }}
    >
      <div style={{ width: '100%', position: 'absolute' }}>
        <Swiper className="mySwiper" initialSlide="1" ref={swiperRef}>
          <SwiperSlide>
            {shiftData.availabilityLists.map((button, index) => (
              <Button
                key={index}
                variant="contained"
                className={`roster_button_${button.className} roster_button`}
                style={{ textTransform: 'none', background: `${button.color}` }}
                onClick={() =>
                  changeShift(button, button.onlyOneChip, button.type, button.isAllday)
                }
              >
                {button.name}
              </Button>
            ))}
          </SwiperSlide>
          <SwiperSlide style={{ height: '68px' }}></SwiperSlide>
          <SwiperSlide style={{ width: '68px', textAlign: 'right' }}>
            <Button variant="contained" className="roster_button_addnote" onClick={handleOpenPopup}>
              Add Note
            </Button>
          </SwiperSlide>
        </Swiper>
        <Modal open={isPopupOpen} onClose={handleClosePopup}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxWidth: 400,
              width: 'calc( 100% - 16px)',
              borderRadius: '8px',
              textAlign: 'center'
            }}
          >
            <TextField
              label="Note"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={noteText}
              onChange={handleNoteChange}
              sx={{ mb: 2 }}
            />
            <Button variant="contained" onClick={handleSubmit} style={{ marginTop: '10px' }}>
              Close
            </Button>
          </Box>
        </Modal>
      </div>
      <Typography
        variant="subtitle2"
        style={{ color: '#ffffff', fontWeight: 'bold', marginLeft: '10px' }}
      >
        {format(shiftData.date, 'EEEE do MMMM')}
      </Typography>
      {isSelected && (
        <div className="chip-wrapper">
          {selectedShifts.map((chip, index) => (
            <Chip
              key={index}
              label={chip.name}
              className={'chip_base' + ` chip_${chip.className}`}
              style={{ background: `${chip.color}` }}
            />
          ))}
        </div>
      )}
      {showSwipeRequest && (
        <p style={{ fontSize: '12px', marginBottom: '0', marginRight: '10px' }}>Swipe → Request</p>
      )}
    </Box>
  );
};

export default AvailableDate;
