import React from 'react';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ExperienceCardList from './ExperienceCardList';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={{ enter: 500 }} />;
});

const ExperienceModalList = ({ open, onClose, experiences, handleCloseExperience, handleSelectExperience, businessName }) => {

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" TransitionComponent={Transition}>
      <DialogTitle>What’s on at <span style={{ fontWeight:'bold'}}>{businessName}</span></DialogTitle>
      <DialogContent style={{background: '#EDEADE'}}>
        <ExperienceCardList handleSelectExperience={handleSelectExperience} experiences={experiences} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseExperience} variant="contained" style={{ background: 'linear-gradient(180deg, #FD6C57, #FD3366 100%)', color: '#fff' }}>
          Skip for Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExperienceModalList;