import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import ImageSlider from '../../components/events/ImageSlider';
import getEventLists from '../../hooks/@events/getEventLists';
import getBusinessProfile from '../../hooks/@common/getBusinessProfile';

// CSS
import '../../assets/scss/events/eventsDisplay.scss';
import '../../assets/scss/events/detail.scss';
import _ from 'lodash';

const SimilarEventsListStyle = ({ eventData, eventType, eventId, widgetBranding }) => {
  const { key } = useParams();
  const [resetSlider, setResetSlider] = useState(false);
  const [eventLists, setEventLists] = useState([]);
  const [isStatus, setIsStatus] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const [eventListsRes] = await Promise.all([getEventLists(key), getBusinessProfile(key)]);
        const today = new Date();
        const filteredEvents = eventListsRes?.data.filter((event) => {
          const expirationDate = new Date(event?.data.expirationDate);
          const isExpired = today >= expirationDate;
          const isSameEvent = event?.data.id === eventId;
          const isMatchingType = event.type === eventType;
          return ((!isExpired && !(isMatchingType && isSameEvent)) || !isMatchingType) && isStatus;
        });
        setEventLists(filteredEvents);
        setIsStatus(eventData?.data.status === 1 ? true : false);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [key]);

  return (
    <Box>
      {!_.isEmpty(eventLists) && (
        <>
          <Typography
            variant="h6"
            style={{
              marginTop: '60px',
              fontWeight: 'bold',
              color: widgetBranding ? widgetBranding.accent_colour : '#fff',
              fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit'
            }}
          >
            Similar Event
          </Typography>
          <div className={'list'}>
            {eventLists.map((event) => (
              <ImageSlider
                key={event.id}
                eventData={event}
                resetSliderProp={resetSlider}
                images={event.data.files.length > 0 ? event.data.files : widgetBranding.files}
                token={key}
                layout={'list'}
                widgetBranding={widgetBranding}
              />
            ))}
          </div>
        </>
      )}
    </Box>
  );
};

export default SimilarEventsListStyle;
