import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container, Drawer } from '@material-ui/core';
import Cookies from 'js-cookie';

// Components
import EventBase from '../../components/utils/EventBase';
import EventBookFilter from '../../components/events/BookFilter';
import TicketFilter from '../../components/events/TicketFilter';

// Hooks
import getEntityEventDetail from '../../hooks/@common/getEntityDetail';
import getBusinessProfile from '../../hooks/@common/getBusinessProfile';
import getWidgetBranding from '../../hooks/@common/getWidgetBranding';

// Utils
import _ from 'lodash';

// CSS
import '../../assets/scss/events/eventsDisplay.scss';
import '../../assets/scss/events/detail.scss';

function EventDetailPage({}) {
  const { id, key, type } = useParams();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [businessProfile, setBusinessProfile] = useState({});
  const [widgetBranding, setWidgetBranding] = useState({});
  const [eventData, setEventData] = useState({});
  const [isExperience, setIsExperience] = useState(false);
  const user_uuid = Cookies.get('uuid');

  useEffect(() => {
    let eventType;
    switch (type) {
      case 'posts':
        eventType = 'post';
        break;
      case 'events':
        eventType = 'experience';
        break;
      case 'tickets':
        eventType = 'ticket';
        break;
      default:
        eventType = null;
        break;
    }
    if (eventType === 'experience') {
      setIsExperience(true);
    }
    async function fetchData() {
      try {
        const [eventRes, businessRes, widgetBrandingRes] = await Promise.all([
          getEntityEventDetail(id, key, eventType, user_uuid),
          getBusinessProfile(key),
          getWidgetBranding(key)
        ]);
        if (_.isEmpty(eventRes.data)) {
          const data = {
            id: 0,
            likes: 0,
            visits: 0,
            shares: 0,
            is_liked: false,
            is_visited: false,
            name: businessRes.data?.user?.business_profile?.business_name,
            type: 'DEFAULT',
            data: {
              id: 0,
              slug: 'default',
              name: businessRes.data?.user?.business_profile?.business_name,
              description: `${businessRes.data?.user?.business_profile?.address} - ${businessRes.data?.user?.business_profile?.phone_no}`,
              resource: null,
              files: widgetBrandingRes.data?.files,

              canBook: 1,
              status: 1
            }
          };

          setEventData(data);
        } else {
          setEventData(eventRes.data);
        }

        setBusinessProfile(businessRes.data);
        setWidgetBranding(widgetBrandingRes.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [id, key]);

  const toggleFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  if (_.isEmpty(eventData) || _.isEmpty(businessProfile)) {
    return (
      <Container
        style={{
          height: '100vh',
          backgroundColor: widgetBranding.background_colour || '#000',
          color: widgetBranding.accent_colour || '#fff',
          padding: '0px',
          position: 'relative'
        }}
        id="eventDetail"
      ></Container>
    );
  }

  return (
    <Container
      maxWidth={false}
      style={{
        backgroundColor: widgetBranding.background_colour || '#000',
        color: widgetBranding.accent_colour || '#fff',
        padding: '0px',
        position: 'relative'
      }}
      id="eventDetail"
    >
      <EventBase
        eventData={eventData}
        token={key}
        businessProfile={businessProfile}
        toggleFilter={() => toggleFilter()}
        widgetBranding={widgetBranding}
      />
      <Drawer
        ModalProps={{
          keepMounted: true
        }}
        anchor="bottom"
        open={isOpenFilter}
        onClose={toggleFilter}
        className="filter-drawer"
        transitionDuration={350}
      >
        <div
          className="filter-wrapper"
          style={{
            backgroundColor: widgetBranding.background_colour || '#000'
          }}
        >
          <Button
            className="close-button"
            onClick={toggleFilter}
            variant="contained"
            style={{ backgroundColor: widgetBranding.accent_colour || '#fff' }}
          />
          {type === 'tickets' ? (
            <TicketFilter
              widgetBranding={widgetBranding}
              ticketData={eventData.data}
              businessProfile={businessProfile}
              eventData={eventData}
            />
          ) : (
            <EventBookFilter
              propTitle={isExperience ? eventData.data.exp_name : eventData.data.name}
              widgetBranding={widgetBranding}
            />
          )}
        </div>
      </Drawer>
    </Container>
  );
}

export default EventDetailPage;
