import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

// React Icons
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { IoLocationOutline } from 'react-icons/io5';

// Utils
import _ from 'lodash';

// CSS
import '../../assets/scss/events/eventsDisplay.scss';
import '../../assets/scss/events/detail.scss';

const defaultStyle = {
  marginTop: '30px'
};
const EventTips = ({ style = {}, startTime, businessName, address, widgetBranding, eventData }) => {
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const combinedStyle = { ...defaultStyle, ...style };
  const eventTime = new Date(eventData?.data?.eventDate);
  const useStyles = makeStyles(() => ({
    container: {
      display: 'flex',
      alignItems: 'center'
    },
    text: {
      marginLeft: '12px',
      fontSize: '14px'
    }
  }));
  const classes = useStyles();

  useEffect(() => {
    if (_.isEmpty(eventData?.data?.eventDate)) {
      setTime('');
      setDate('');
      return;
    }

    const eventTime = new Date(eventData?.data?.eventDate);
    const today = new Date();

    // Format the date as 'Aug 28 2024'
    const formattedDate = eventTime.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });

    const formattedTime = eventTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });

    // Compare event date with today
    const isTomorrow =
      eventTime.getFullYear() === today.getFullYear() &&
      eventTime.getMonth() === today.getMonth() &&
      eventTime.getDate() === today.getDate() + 1;

    if (isTomorrow) {
      setDate('tomorrow');
    } else {
      setDate(formattedDate);
    }

    setTime(formattedTime);
  }, [eventData]);

  return (
    <Grid style={combinedStyle}>
      {!_.isEmpty(time) && (
        <div className={`${classes.container} tip-list`}>
          <IoMdInformationCircleOutline style={{ width: '24px', height: '24px' }} />
          <span className={classes.text}>
            Show at {date} @ {time}
          </span>
        </div>
      )}
      <div className={`${classes.container} tip-list`}>
        <IoLocationOutline style={{ width: '24px', height: '24px' }} />
        <div className="location-info">
          <span className={classes.text}>{businessName}</span>
          <span
            className={classes.text}
            style={{
              color: widgetBranding?.accent_colour || '#fff',
              display: 'block',
              lineHeight: '1'
            }}
          >
            {address}
          </span>
        </div>
      </div>
    </Grid>
  );
};
export default EventTips;
