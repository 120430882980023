import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Grid, Typography, Paper, makeStyles, IconButton } from '@material-ui/core';
import { IoChevronBackSharp } from 'react-icons/io5';
import { IoChevronForwardSharp } from 'react-icons/io5';
import EventDescription from './EventDescription';

import '../../assets/scss/events/eventsDisplay.scss';
import { s3Bucket } from '../../config';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  leftButton: {
    position: 'absolute',
    left: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#fff'
  },
  rightButton: {
    position: 'absolute',
    right: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#fff'
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: '18px',
    '@media (min-width: 768px)': {
      fontSize: '22px'
    }
  },
  description: {
    fontSize: '14px'
  },
  date: {
    fontSize: '12px'
  }
}));

const ImageSlider = ({ eventData, resetSliderProp, images, token, layout, widgetBranding }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [resetSlider, setResetSlider] = React.useState(false);
  const [eventDate, setEventDate] = useState('');
  const [isExperience, setIsExperience] = useState(false);
  const [isStatus, setIsStatus] = useState(true);

  useEffect(() => {
    if (_.isEmpty(eventData)) return;

    if (eventData.type === 'EXPERIENCE') setIsExperience(true);
    setIsStatus(eventData.data.status === 1 ? true : false);

    if (_.isEmpty(eventData.data.startDate)) {
      setEventDate('');
      return;
    }
    const date = new Date(eventData.data.startDate.replace(' ', 'T'));
    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    };

    setEventDate(new Intl.DateTimeFormat('en-GB', options).format(date));
  }, [eventData]);

  const playerRef = useRef(null);

  const handleNext = () => {
    setValue((prevValue) => (prevValue === images.length - 1 ? 0 : prevValue + 1));
  };

  const handlePrev = () => {
    setValue((prevValue) => (prevValue === 0 ? images.length - 1 : prevValue - 1));
  };

  React.useEffect(() => {
    if (resetSlider) {
      setValue(0);
      setResetSlider(false);
    }
  }, [resetSlider]);

  React.useEffect(() => {
    if (resetSliderProp) {
      setResetSlider(true);
    }
  }, [resetSliderProp]);

  const getLinkPath = () => {
    const baseURL = `${eventData.data?.id}/${token}`;
    let pathname;
    switch (eventData.type) {
      case 'POST':
        pathname = `/posts/${baseURL}`;
        break;
      case 'EXPERIENCE':
        pathname = `/events/${baseURL}`;
        break;
      case 'TICKET':
        pathname = `/tickets/${baseURL}`;
        break;
      default:
        pathname = '/';
    }
    return {
      pathname: pathname,
      state: {
        layout: layout
      }
    };
  };
  if (!isStatus) {
    return null;
  }

  return (
    <Grid className={`post-card ${layout}`} container position="relative">
      <Grid item className="post-card_contents" xs={12} style={{ position: 'relative' }}>
        <div className="image-wrapper">
          <Paper className="image-wrapper_content" style={{ backgroundColor: '#000' }}>
            <Link to={getLinkPath()} style={{ width: '100%' }}>
              {!_.isEmpty(images) ? (
                <img
                  className="carouselItem"
                  src={s3Bucket + images[value].path}
                  alt={images[value].alt}
                />
              ) : (
                <img
                  className="carouselItem"
                  src="https://www.survivorsuk.org/wp-content/uploads/2017/01/no-image.jpg"
                  alt="No images available"
                />
              )}
            </Link>
          </Paper>

          {!_.isEmpty(images) && images.length > 1 && (
            <div className="image-wrapper_arrows">
              <IconButton
                style={{ backgroundColor: 'rgba(0,0,0,.47)' }}
                className={classes.leftButton}
                onClick={handlePrev}
              >
                <IoChevronBackSharp style={{ color: '#fff' }} />
              </IconButton>
              <IconButton
                style={{ backgroundColor: 'rgba(0,0,0,.47)' }}
                className={classes.rightButton}
                onClick={handleNext}
              >
                <IoChevronForwardSharp style={{ color: '#fff' }} />
              </IconButton>
            </div>
          )}
        </div>
        <div className="typo-wrapper">
          <div className={classes.content}>
            <Typography
              variant="body1"
              className={`${classes.title} type-wrapper_title`}
              style={{
                fontWeight: 'bold',
                fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit'
              }}
            >
              {isExperience ? <>{eventData.data.exp_name}</> : <>{eventData.data.name}</>}
            </Typography>
            <Typography
              variant="body1"
              className={classes.description}
              component="div"
              style={{ fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit' }}
            >
              <EventDescription
                isExperience={isExperience}
                eventData={eventData}
                widgetBranding={widgetBranding}
              />
            </Typography>
            <Typography
              variant="body2"
              className={`${classes.date}`}
              style={{ fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit' }}
            >
              {eventDate}
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
export default ImageSlider;
