import React, { useEffect, useState } from 'react';
import { getGooglePlaces } from '../../hooks/@booking/useGooglePlaces';
import { googleMapKey } from '../../config';

const defaultStyle = {
  width: '100%',
  height: '450px',
  border: '0'
};
const GoogleMap = ({ businessName, businessAddress, style = {} }) => {
  const [placeId, setPlaceId] = useState(null);
  const [embedUrl, setEmbedUrl] = useState(null);
  const combinedStyle = { ...defaultStyle, ...style };
  useEffect(() => {
    const fetchData = async () => {
      if (businessName && businessAddress) {
        const params = { textQuery: `${businessName}, ${businessAddress}` };

        try {
          const response = await getGooglePlaces(params);

          if (response.status === 200) {
            setPlaceId(response?.data.id);
          }
        } catch (error) {
          console.error('Error fetching Google Places:', error);
        }
      }
    };
    fetchData();
  }, [businessAddress, businessName]);

  useEffect(() => {
    setEmbedUrl(
      placeId
        ? `https://www.google.com/maps/embed/v1/place?key=${googleMapKey}&q=place_id:${placeId}`
        : null
    );
  }, [placeId]);

  return (
    <>
      {placeId && (
        <>
          <iframe
            title="Embedded Google Map"
            style={combinedStyle}
            src={embedUrl}
            allowFullScreen
          ></iframe>
        </>
      )}
    </>
  );
};

export default GoogleMap;
