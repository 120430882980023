import { useState, useEffect } from 'react';
import axios from 'axios';
import { URL } from '../../config';

const useAvailableTimeSlots = (key, api ='widget/v2') => {
  const [loadingChanges, setLoading] = useState(false);
  const [isChooseSectionAllowed, setIsChooseSectionAllowed] = useState(true);
  const [availableTimeSlot, setAvailableTimeSlot] = useState([]);
  const [waitlistTimeSlots	, setWaitlistTimeSlots	] = useState([]);
  const [futureTimeSlots, setFutureTimeSlots] = useState([]);
  const [allFloors, setAllFloors] = useState([]);
  const [selectedFloors, setSelectedFloors] = useState([]);
  const [bookingTime, setBookingTime] = useState('');
  const [displaySearchResult, setDisplaySearchResult] = useState(false);
  const [showAlternate, setShowAlternate] = useState(false);
  const [displayNoResultFound, setDisplayNoResultFound] = useState(false);
  const [displayHasSpecialDay, setDisplayHasSpecialDay] = useState(false);
  const [displayHasNoShift, setDisplayHasNoShift] = useState(false);
  const [isAllowWaitlist , setIsAllowWaitlist ] = useState(false);

  const loadAvailableTime = (partySize, date, time, experience_id) => {
    setLoading(true);
    setDisplayNoResultFound(false);
    setDisplayHasNoShift(false);
    setDisplayHasSpecialDay(false);
    setDisplaySearchResult(false);
    setShowAlternate(false);
    const dateParam = `&date=${date.toISOString().split('T')[0]}`;
    axios({
      method: 'GET',
      url: `${URL}/${api}/tables?date=${dateParam}&time=${time}&partySize=${partySize}${experience_id ? "&experience_id="+experience_id : ""}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${key}`
      }
    })
      .then((response) => {
        let responseData = response.data.data;
        if (
          (Array.isArray(responseData.availableTimeSlot) &&
            responseData.availableTimeSlot.length)
          ||
          (responseData.futureTimeSlot)
        ) {
          const filteredData = responseData.availableTimeSlot.filter(shift => shift.available);
          const transformedData = filteredData.map(shift => {
            if (!responseData.isChooseSectionAllowed) {
              shift.floors = [shift.floors[0]];
            }
            return shift;
          });
          setAvailableTimeSlot(responseData.isChooseSectionAllowed ? responseData.availableTimeSlot : transformedData);
          setIsChooseSectionAllowed(responseData.isChooseSectionAllowed);
          setFutureTimeSlots(responseData.futureTimeSlot);
          setAllFloors(responseData.allFloors);
          setLoading(false);
          setSelectedFloors([]);
          setBookingTime('');
          setDisplaySearchResult(true);
          setShowAlternate(responseData.availableTimeSlot.length > 0 ? false : true);
          setDisplayNoResultFound(responseData.availableTimeSlot.length > 0 ? false : true);

          return;
        }

        if (responseData.hasSpecialDay) {
          setDisplayNoResultFound(true);
          setDisplayHasSpecialDay(true);
          setLoading(false);
          setAvailableTimeSlot([]);
          return;
        }

        if (responseData.noShiftAvailable) {
          setDisplayNoResultFound(true);
          setDisplayHasNoShift(true);
          setLoading(false);
          setAvailableTimeSlot([]);
          return;
        }

        setDisplayNoResultFound(true);
        setAvailableTimeSlot(responseData.availableTimeSlot);
        setWaitlistTimeSlots(responseData.waitlistTimeSlots);
        setFutureTimeSlots(responseData.futureTimeSlot);
        setIsAllowWaitlist(responseData.isAllowWaitlist);
        setIsChooseSectionAllowed(responseData.isChooseSectionAllowed);
        setLoading(false);
      })
      .catch((error) => {
        setDisplayNoResultFound(true);
        setDisplayHasNoShift(true);
        setLoading(false);
        setAvailableTimeSlot(null);
      });
  };

  return {
    loadingChanges,
    availableTimeSlot,
    setAvailableTimeSlot,
    futureTimeSlots,
    allFloors,
    selectedFloors,
    bookingTime,
    displaySearchResult,
    showAlternate,
    displayNoResultFound,
    displayHasSpecialDay,
    displayHasNoShift,
    loadAvailableTime,
    isChooseSectionAllowed,
    isAllowWaitlist,
    waitlistTimeSlots
  };
};

export default useAvailableTimeSlots;
