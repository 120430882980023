import axios from 'axios';
import { URL } from '../../config';

const getMyBooking = async (id, key) => {
  return axios({
    method: 'GET',
    url: `${URL}/widget/v2/booking/${id}/full`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${key}`,
    },
  })
    .then((response) => {
      const responseData = response.data.data;
      const booking = responseData.booking;

      if (booking) {
        return {
          data: booking,
          status: response.status,
          error: null,
          isLoading: false,
        };
      }

      return {
        data: null,
        status: response.status,
        error: 'No booking data available',
        isLoading: false,
      };
    })
    .catch((error) => {
      console.error('Error fetching booking:', error);

      return {
        data: null,
        status: error.response ? error.response.status : 500,
        error: error.response ? error.response.data.message : 'Internal Server Error',
        isLoading: false,
      };
    });
};

export default getMyBooking;