import axios from 'axios';
import { URL } from '../../config';

export const getShifts = async (token) => {
  try {
      const response = await axios.get(`${URL}/widget/me`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
    const res = {
      data: response.data.data.shifts,
      status: response.status,
      error: null,
      isLoading: false,
    };
    return res;
  } catch (error) {
    console.error('Error get shifts:', error);

    const res = {
      data: null,
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data.message : 'Internal Server Error',
      isLoading: false,
    };

    return res;
  }
};