import React from 'react';
import { RiHome2Line } from 'react-icons/ri';
import { s3Bucket } from '../../config';
const UserProfileIcon = ({ IconImage }) => {
  const imgSrc = IconImage ? `${s3Bucket}${IconImage}` : null;

  return (
    <>
      <div className="icon-container">
        <div className="icon-image">
          {imgSrc ? (
            <img src={imgSrc} alt="Home" />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'
              }}
            >
              <RiHome2Line style={{ width: '60%', height: '60%', color: 'gray' }} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserProfileIcon;
