import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, Select, MenuItem } from '@material-ui/core';
import '../../assets/scss/events/eventsDisplay.scss';
import '../../assets/scss/events/detail.scss';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  inputBase: {
    '& .MuiInputBase-input': {
      color: (props) => props.color
    }
  }
}));

function PartySizeFilter({ value, onChange, options, widgetBranding }) {
  const [guestCount, setGuestCount] = useState(0);
  const [numMaximumGuest, setNumMaximumGuest] = useState(0);
  const [numMinimumGuest, setNumMinimumGuest] = useState(0);
  useEffect(() => {
    if (options[0]) {
      setNumMinimumGuest(options[0].value);
      setNumMaximumGuest(options.slice(-1)[0].value);
    }
  }, [options]);

  useEffect(() => {
    setGuestCount(value);
  }, [value]);

  const handleIncrement = () => {
    if (numMaximumGuest > guestCount) {
      setGuestCount((prevCount) => prevCount + 1);
      onChange(guestCount + 1);
    }
  };

  const handleDecrement = () => {
    if (guestCount > numMinimumGuest) {
      setGuestCount((prevCount) => prevCount - 1);
      onChange(guestCount - 1);
    }
  };

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setGuestCount(newValue);
    onChange(newValue);
  };
  const classes = useStyles({ color: `${widgetBranding?.accent_colour || ''}!important` });
  return (
    <>
      {options.length && (
        <Box
          style={{
            display: 'flex',
            borderBottom: '1px solid',
            borderColor: widgetBranding?.accent_colour || '',

            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Grid style={{ flexGrow: '1', color: widgetBranding?.accent_colour || '#fff' }}>
            <Typography variant="body1" style={{ fontSize: '12px' }}>
              Party Size
            </Typography>
            <Select
              value={value}
              onChange={handleSelectChange}
              IconComponent={() => null}
              className={classes.inputBase}
              style={{
                color: widgetBranding?.accent_colour || ''
              }}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid style={{ display: 'flex', gap: '16px' }}>
            <Button
              variant="text"
              style={{
                color: widgetBranding?.accent_colour || '#fff',
                padding: '0',
                minWidth: 'auto'
              }}
              onClick={handleDecrement}
            >
              <FiMinus style={{ fontSize: '20px' }} />
            </Button>
            <Button
              variant="text"
              style={{
                color: widgetBranding?.accent_colour || '#fff',
                padding: '0',
                minWidth: 'auto'
              }}
              onClick={handleIncrement}
            >
              <FiPlus style={{ fontSize: '20px' }} />
            </Button>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default PartySizeFilter;
