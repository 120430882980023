import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, Typography, Button, Container } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import useBusinessProfile from '../../hooks/@booking/useBusinessProfile';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import useTimeslots from '../../hooks/@booking/useTimeslots';
import useAvailableTimeSlots from '../../hooks/@booking/useAvailableTimeSlots';
import BounceLoader from 'react-spinners/BounceLoader';
import { useHistory } from 'react-router-dom';
import '../../assets/scss/events/eventsDisplay.scss';
import '../../assets/scss/events/detail.scss';
import { formatDate } from 'react-day-picker/moment';
import useStyles from '../../styles/events/styles';
import PartySizeFilter from './PartySizeFilter';
import SelectComponent from '../utils/SelectComponent';
import { useParams } from 'react-router-dom';
import iconWabi from '../../assets/images/icon-wabi.png';
//hooks
import { createBooking } from '../../hooks/@booking/useBooking';
import { getAvailableDates } from '../../hooks/@booking/useAvailableDates';
// Utils
import _, { set } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
const useStylesCustom = makeStyles((theme) => ({
  datePickerInput: {
    color: (props) => props.accent_colour || '#000'
  }
}));
function EventBookFilter({
  isAside = false,
  isDialog = false,
  openFilterDialog,
  propDate,
  propPartySize,
  propTime,
  propTitle,
  widgetBranding
}) {
  const { key } = useParams();
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [isWaitlist, setIsWaitlist] = useState(false);
  const [showWaitListSlot, setShowWaitListSlot] = useState(false);
  const [promptMessage, setPromptMessage] = useState('');
  const classes = useStyles({ accent_colour: widgetBranding.accent_colour || '#fff' });
  const classesCustom = useStylesCustom({ accent_colour: widgetBranding.accent_colour || '#fff' });
  const availableTimeSlotRef = useRef(false);
  const [selectedPartySize, setSelectedPartySize] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [experienceId, setExperienceId] = React.useState(null);
  const [experiencePrice, setexperiencePrice] = React.useState(0);
  const [experienceName, setexperienceName] = React.useState(0);
  const [availableDates, setAvailableDates] = React.useState([]);
  const { timeslots, selectedTime, setSelectedTime, error } = useTimeslots(
    selectedDate,
    experienceId,
    key
  );
  const [filteredTimeSlots, setfilteredTimeSlots] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState('');
  const [floorOptions, setFloorOptions] = useState([]);
  // const [daysOfWeek, setdaysOfWeek] = React.useState([]);
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
    setShowPrompt(false);
    setPromptMessage(null);
    setIsWaitlist(false);
    setShowWaitListSlot(false);
    availableTimeSlotRef.current = false;
  };
  const {
    availableTimeSlot,
    waitlistTimeSlots,
    loadAvailableTime,
    loadingChanges,
    setAvailableTimeSlot,
    displayNoResultFound,
    displayHasSpecialDay,
    displayHasNoShift,
    isChooseSectionAllowed,
    isAllowWaitlist
  } = useAvailableTimeSlots(key);
  const [hasNoShift, setHasNoShift] = useState(displayHasNoShift);
  const [noResultFound, setNoResultFound] = useState(displayNoResultFound);
  const [hasSpecialDay, setHasSpecialDay] = useState(displayHasSpecialDay);
  const { businessProfileData } = useBusinessProfile(key);
  const shouldDisableDate = (date) => {
    const today = formatDate(date, 'YYYY-MM-DD');
    const isAvailable = availableDates[0].includes(today);
    return !isAvailable;
  };
  const fetchAvailableDates = async (date) => {
    const params = {
      date: formatDate(date, 'YYYY-MM-DD')
    };
    const availableDatesResponse = await getAvailableDates(params, key);
    setAvailableDates(availableDatesResponse.data);
  };
  useEffect(() => {
    fetchAvailableDates(new Date());
  }, []);
  useEffect(() => {
    setHasNoShift(displayHasNoShift);
    setNoResultFound(displayNoResultFound);
    setHasSpecialDay(displayHasSpecialDay);
  }, [displayNoResultFound, displayHasSpecialDay, displayHasNoShift]);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
    setAvailableTimeSlot([]);
    setfilteredTimeSlots([]);
    setShowPrompt(false);
    setPromptMessage(null);
    setHasNoShift(null);
    setNoResultFound(null);
    setHasSpecialDay(null);
    setIsWaitlist(false);
    setShowWaitListSlot(false);
    availableTimeSlotRef.current = false;
  };
  const handlePartySizeChange = (val) => {
    setSelectedPartySize(val);
    setIsWaitlist(false);
    setShowWaitListSlot(false);
    availableTimeSlotRef.current = false;
  };
  const handleAddToWaitlist = () => {
    setShowWaitListSlot(true);
  };
  const handleDeclineWaitlist = () => {
    setIsWaitlist(false);
    setShowWaitListSlot(false);
  };
  const {
    partySize,
    partySizes,
    businessName,
    widgetMessageTitle,
    widgetMessage,
    onlinePaymentFee
  } = businessProfileData;

  useEffect(() => {
    if (!availableTimeSlot) return;
    const floors = availableTimeSlot.flatMap((slot) =>
      slot.floors.map((floor) => ({ value: floor.id, text: floor.floor_name }))
    );
    setFloorOptions(_.uniqBy(floors, 'value'));
  }, [availableTimeSlot]);
  const handleFloorChange = (floorId) => {
    setSelectedFloor(floorId);
  };

  useEffect(() => {
    if (!availableTimeSlot || availableTimeSlot.length === 0) {
      setfilteredTimeSlots([]);
      return;
    }
    const filtered =
      selectedFloor === ''
        ? availableTimeSlot
        : availableTimeSlot.filter((slot) =>
            slot.floors.some((floor) => floor.id.toString() === selectedFloor)
          );
    setfilteredTimeSlots(filtered);
  }, [availableTimeSlot, selectedFloor]);

  async function handleCreateBooking(shiftId, floorId, floorName, userId, timeLabel, time) {
    const data = {
      userId: userId,
      party_size: selectedPartySize,
      date: formatDate(selectedDate, 'YYYY-MM-DD'),
      time: time,
      booking_time: time + '',
      shift_id: shiftId,
      experience_id: experienceId,
      experience_name: experienceName,
      price: experiencePrice,
      widgetNoteTitle: widgetMessageTitle,
      widgetNoteMessage: widgetMessage,
      onlinePaymentFee: onlinePaymentFee,
      floor_id: floorId + '',
      wants_waitlist: isWaitlist
    };
    const bookingResponse = await createBooking(data, key);
    if (bookingResponse.status !== 500) {
      const bookingData = {
        date: formatDate(selectedDate, 'dddd, D MMM'),
        time: timeLabel,
        partySize: bookingResponse.data.party_size,
        floor_name: floorName,
        createdAt: bookingResponse.data.created_at,
        shift_id: bookingResponse.data.shift_id,
        experience_id: experienceId,
        experience_name: experienceName,
        price: experiencePrice,
        widgetNoteTitle: widgetMessageTitle,
        widgetNoteMessage: widgetMessage,
        onlinePaymentFee: bookingResponse.bookingFee,
        businessName: businessName,
        floorName: floorName,
        chooseFloor: widgetBranding.enable_section_filter === 1,
        displaySection: widgetBranding.display_section_name === 1
      };
      const newUrl = `/step2/${bookingResponse.data.id}/${key}`;
      history.push({
        pathname: newUrl,
        bookingData: bookingData
      });
    } else {
      setPromptMessage(bookingResponse.error);
      setShowPrompt(true);
    }
  }
  useEffect(() => {
    if (!propPartySize && partySize && partySizes.includes(partySize)) {
      setSelectedPartySize(partySize);
    }
  }, [propPartySize, partySize, partySizes]);
  function getPartySizeText(size) {
    return size === 1 ? `${size} Person` : `${size} Guests`;
  }
  function convertTimeToSeconds(timeString) {
    const match = timeString.match(/(\d{1,2}):(\d{1,2})([APMapm]{2})/);
    if (!match) {
      return null;
    }
    const [hours, minutes, meridian] = match.slice(1, 4);
    let hoursIn24Format = parseInt(hours, 10);
    if (isNaN(hoursIn24Format) || isNaN(parseInt(minutes, 10))) {
      return null;
    }
    if (meridian.toLowerCase() === 'pm' && hoursIn24Format !== 12) {
      hoursIn24Format += 12;
    } else if (meridian.toLowerCase() === 'am' && hoursIn24Format === 12) {
      hoursIn24Format = 0;
    }
    const totalSeconds = hoursIn24Format * 3600 + parseInt(minutes, 10) * 60;
    return totalSeconds;
  }
  function getCurrentTimeFormatted() {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const meridian = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')}${meridian}`;
    return convertTimeToSeconds(formattedTime);
  }
  useEffect(() => {
    if (timeslots && timeslots.length > 0) {
      const formattedSelectedDate = formatDate(selectedDate, 'YYYY-MM-DD');
      const formattedCurrentDate = formatDate(new Date(), 'YYYY-MM-DD');
      const currentTime = getCurrentTimeFormatted();
      if (formattedSelectedDate === formattedCurrentDate) {
        const resTimeslots = timeslots.filter((timeslot) => timeslot.value >= currentTime);
        setfilteredTimeSlots(resTimeslots);
        if (resTimeslots.length > 0) {
          if (propTime) {
            setSelectedTime(propTime);
          } else {
            setSelectedTime(resTimeslots[0].value);
          }
        } else {
          setSelectedTime([]);
        }
      } else {
        setfilteredTimeSlots(timeslots);
        if (propTime) {
          setSelectedTime(propTime);
        } else {
          setSelectedTime(timeslots[0].value);
        }
      }
    }
  }, [propTime, timeslots]);
  useEffect(() => {
    if (propPartySize) setSelectedPartySize(propPartySize);
    if (propDate) setSelectedDate(propDate);
    if (propTime) setSelectedTime(propTime);
  }, [propDate, propPartySize, propTime]);
  useEffect(() => {
    if (selectedPartySize && selectedDate && selectedTime && !availableTimeSlotRef.current) {
      loadAvailableTime(selectedPartySize, selectedDate, selectedTime, experienceId);
      availableTimeSlotRef.current = true;
      setIsWaitlist(isAllowWaitlist);
      setShowPrompt(false);
      setPromptMessage(null);
    }
  }, [selectedPartySize, selectedDate, selectedTime, loadAvailableTime]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    fetchAvailableDates(selectedDate);
  };
  const containerStyle = (() => {
    if (isAside) {
      return {
        borderRadius: '10px',
        border: '1px solid',
        borderColor: widgetBranding?.accent_colour || '#fff',
        padding: '16px',
        position: 'relative',
        minWidth: '290px',
        width: '290px',
        height: 'auto',
        margin: 'unset'
      };
    } else if (isDialog) {
      return {
        padding: '16px',
        backgroundColor: widgetBranding?.background_colour || '#000',
        color: widgetBranding?.accent_colour || '#fff'
      };
    } else {
      return {
        padding: '32px 0 12px',
        position: 'relative',
        backgroundColor: widgetBranding?.background_colour || '#000',
        color: widgetBranding?.accent_colour || '#fff'
      };
    }
  })();
  const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#90caf9'
      },
      secondary: {
        main: '#f48fb1'
      },
      background: {
        default: '#303030',
        paper: '#424242'
      },
      text: {
        primary: '#fff'
      }
    }
  });
  return (
    <Container maxWidth="lg" style={containerStyle}>
      <div
        className={`allWrapper ${isAside ? 'allWrapper-aside' : ''} ${
          isDialog ? 'allWrapper-dialog' : ''
        }`}
      >
        <Grid className="filterItem-wrapper">
          <div className="dialog-head">
            <Typography variant="body1" style={{ fontSize: '10px' }}>
              Make Reservation At {propTitle}
            </Typography>
            <img src={iconWabi} alt="Wabi Icon" style={{ width: '45px' }} />
          </div>
          <PartySizeFilter
            value={selectedPartySize || ''}
            onChange={handlePartySizeChange}
            options={partySizes.map((size) => ({
              value: size,
              text: getPartySizeText(size)
            }))}
            widgetBranding={widgetBranding}
          />
          <ThemeProvider theme={darkTheme}>
            <MuiPickersUtilsProvider className="filterItem" utils={DateFnsUtils}>
              <Grid
                item
                style={{
                  borderBottom: '1px solid',
                  borderColor: widgetBranding?.accent_colour || '#fff'
                }}
              >
                <Typography variant="body1" component="div" style={{ fontSize: '12px' }}>
                  Date
                </Typography>
                <KeyboardDatePicker
                  variant="inline"
                  open={open}
                  onOpen={handleOpen}
                  onClose={() => setOpen(false)}
                  format="EEE, MMM d"
                  minDate={new Date()}
                  maxDate={new Date('5000-01-01')}
                  id="date-picker-inline"
                  className={`datepicker ${classes.datePicker}`}
                  value={selectedDate}
                  onChange={handleDateChange}
                  shouldDisableDate={shouldDisableDate}
                  onMonthChange={(date) => {
                    fetchAvailableDates(date);
                  }}
                  autoOk={true}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    style: {
                      color: widgetBranding.accent_colour || '#fff'
                    }
                  }}
                  InputProps={{
                    readOnly: true,
                    classes: {
                      input: classesCustom.datePickerInput
                    },
                    onClick: (e) => setOpen(true)
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
          <Grid
            item
            className="filterItem"
            style={{
              borderBottom: '1px solid',
              borderColor: widgetBranding.accent_colour || '#fff'
            }}
          >
            <Typography variant="body1" component="div" style={{ fontSize: '12px' }}>
              Time
            </Typography>
            {filteredTimeSlots.length && (
              <SelectComponent
                value={selectedTime}
                onChange={handleTimeChange}
                options={
                  filteredTimeSlots
                    ? [...new Set(filteredTimeSlots.map((slot) => slot.value))].map((value) => ({
                        value,
                        text: filteredTimeSlots.find((slot) => slot.value === value).label
                      }))
                    : []
                }
                className={classes.select}
                fontSize="22"
                icon="time"
                style={{ color: widgetBranding?.accent_colour || '#fff' }}
                widgetBranding={widgetBranding}
              />
            )}
          </Grid>
          {widgetBranding.enable_section_filter === 1 && (
            <>
              <div
                className="filterItem"
                style={{
                  borderBottom: '1px solid',
                  borderColor: widgetBranding.accent_colour || '#fff'
                }}
              >
                <Typography variant="body1" component="div" style={{ fontSize: '12px' }}>
                  Floor
                </Typography>
                <div className="">
                  <select
                    id="floorSelect"
                    value={selectedFloor}
                    onChange={(e) => handleFloorChange(e.target.value)}
                    style={{
                      width: '100%',
                      border: widgetBranding.accent_colour || '#fff',
                      backgroundColor: widgetBranding.background_colour || '#000',
                      color: widgetBranding.accent_colour || '#fff'
                    }}
                  >
                    <option value="">All Floors</option>
                    {floorOptions.map((floor) => (
                      <option key={floor.value} value={floor.value} className="text-white">
                        {floor.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          )}
        </Grid>
        {isAside === false ? (
          <Box
            marginTop={2}
            style={{
              textAlign: 'justify',
              overflowY: 'auto',
              maxHeight: '300px'
            }}
          >
            {loadingChanges ? (
              <Grid
                container
                className={`loading-box ${classes.fullWidthGrid}`}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <div className="sweet-loading">
                  <BounceLoader
                    css={classes.override}
                    size={30}
                    color={'#FE7D86'}
                    loading={loadingChanges}
                  />
                </div>
              </Grid>
            ) : (
              <div
                className="availableTimes-wrapper"
                style={{
                  backgroundColor: widgetBranding.background_colour || '#000'
                }}
              >
                {error && _.isEmpty(filteredTimeSlots) && error.message ? (
                  <Typography variant="subtitle1" component="div">
                    {error.message}
                  </Typography>
                ) : hasNoShift && noResultFound ? (
                  <Typography variant="subtitle1" component="div">
                    Sorry, there are currently no tables available at this time. Please select a
                    different date or time.
                  </Typography>
                ) : hasSpecialDay && noResultFound ? (
                  <Typography variant="subtitle1" component="div">
                    Sorry, we are closed on this date. Please select a different date.
                  </Typography>
                ) : noResultFound ? (
                  isAllowWaitlist && isWaitlist && !showWaitListSlot ? (
                    <Typography variant="subtitle1" component="div">
                      Sorry, there are currently no tables available at this time. Would you like to
                      be added to the waitlist?
                      <div>
                        <Button variant="contained" onClick={handleAddToWaitlist}>
                          Yes
                        </Button>
                        <Button variant="contained" onClick={handleDeclineWaitlist}>
                          No
                        </Button>
                      </div>
                    </Typography>
                  ) : _.isEmpty(waitlistTimeSlots) ? (
                    <Typography variant="subtitle1" component="div">
                      Sorry, there are currently no tables available at this time. Please select a
                      different date or time.
                    </Typography>
                  ) : null
                ) : null}
                {showPrompt && <Typography component="div">{promptMessage}</Typography>}
                <Grid container className={classes.container}>
                  {filteredTimeSlots.length > 0 ? (
                    filteredTimeSlots.map((timeslot, index) =>
                      timeslot.available
                        ? timeslot.floors.map((floor, floorIndex) => (
                            <Grid className={classes.timeslot} item key={`${index}-${floorIndex}`}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'stretch'
                                }}
                              >
                                <Button
                                  onClick={() =>
                                    handleCreateBooking(
                                      timeslot.shift_id,
                                      floor.id,
                                      floor.floor_name,
                                      floor.user_id,
                                      timeslot.label,
                                      timeslot.value
                                    )
                                  }
                                  key={floor.id}
                                  variant="contained"
                                  className={classes.button}
                                  style={{
                                    color: widgetBranding?.accent_colour || '#fff',
                                    borderColor: widgetBranding?.accent_colour || '#fff'
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    style={{ width: '100%', justifyContent: 'space-between' }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: 'bold',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        fontSize: '14px'
                                      }}
                                    >
                                      {timeslot.label}
                                    </div>
                                    {widgetBranding.display_section_name === 1 ? (
                                      <div
                                        style={{
                                          width: '100%',
                                          overflowWrap: 'break-word',
                                          fontSize: '9px'
                                        }}
                                      >
                                        {floor.floor_name}
                                      </div>
                                    ) : null}
                                  </Box>
                                </Button>
                              </div>
                            </Grid>
                          ))
                        : null
                    )
                  ) : (
                    <Typography variant="subtitle1">No available times for this floor.</Typography>
                  )}
                </Grid>
              </div>
            )}
          </Box>
        ) : (
          <Button
            className="functionButton"
            onClick={() =>
              openFilterDialog(selectedDate, selectedPartySize, selectedTime, selectedFloor)
            }
            style={{
              marginTop: '16px',
              backgroundColor: widgetBranding?.book_now_colour || '#485df9',
              color: widgetBranding.has_button_font_colour
                ? widgetBranding.accent_colour || '#fff'
                : '#fff',
              fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit'
            }}
            variant="contained"
          >
            Book Now
          </Button>
        )}
      </div>
    </Container>
  );
}
export default EventBookFilter;
