// styles.js

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 75,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      padding: 0
    },
    backgroundColor: '#000'
  },
  container: {
    gap: '16px 2%'
  },
  timeslot: {
    width: '32%'
  },
  button: {
    color: '#ffffff',
    lineHeight: '1.4',
    padding: '10px',
    background: 'transparent',
    borderRadius: '40px',
    border: '1px solid #fff',
    transition: '.25s',
    '&:hover': {
      color: '#666!important',
      backgroundColor: '#fff!important'
    }
  },

  imagePaper: {
    borderRadius: '20px'
  },
  selectPaper: {
    backgroundColor: '#000'
  },
  fullWidthGrid: {
    width: '100%'
  },
  select: {
    color: '#fff',
    textAlign: 'left',
    width: '100% !important',
    '&:focus': {
      outline: 'none'
    }
  },
  override: {
    display: 'block',
    margin: '0, auto',
    borderColor: 'red'
  }
}));

export default useStyles;
